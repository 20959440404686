<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <v-btn
              style="position: absolute; top: -15px; right: 55px; z-index: 1"
              color="success"
              small
              fab
              title="Nuevo Modelo"
              @click="abrirModal"
            >
              <v-icon>fas fa-file-upload</v-icon>
            </v-btn>
            <v-expansion-panel-header
              class="py-0 px-4 text-h5"
              style="cursor: default"
            >
              <div>
                <v-icon class="mb-1" left>fas fa-filter</v-icon>
                Filtros
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>

            <!-- Filtros -->
            <v-expansion-panel-content class="pt-1">
              <v-form @submit.prevent="buscar">
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="3" md="2" class="py-1">
                    Empresa
                    <v-autocomplete
                      v-model="filtro.empresa"
                      item-text="nombre"
                      return-object
                      hide-details
                      outlined
                      dense
                      :items="familias"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="2" class="py-1">
                    Fecha desde
                    <FechaPicker
                      v-model="filtro.fecha_desde"
                      hide-details
                      clearable
                    />
                  </v-col>
                  <v-col cols="12" sm="3" md="2" class="py-1">
                    Fecha hasta
                    <FechaPicker
                      v-model="filtro.fecha_hasta"
                      hide-details
                      clearable
                    />
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Proveedor
                    <v-autocomplete
                      v-model="filtro.proveedor"
                      item-text="nombre"
                      return-object
                      hide-details
                      outlined
                      dense
                      clearable
                      :items="proveedores"
                      @change="setCuit"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="0" sm="3" md="2" class="py-1"> </v-col>

                  <v-col cols="12" sm="3" md="2" class="py-1">
                    Cuit
                    <v-autocomplete
                      v-model="filtro.cuit"
                      item-text="nombre"
                      return-object
                      hide-details
                      outlined
                      dense
                      clearable
                      :items="cuitsFiltrados"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="3" md="2" class="py-1">
                    Estado
                    <v-autocomplete
                      v-model="filtro.estado"
                      item-text="nombre_estado"
                      return-object
                      hide-details
                      outlined
                      dense
                      clearable
                      :items="estados"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="0" sm="3" md="3" class="py-1"> </v-col>
                  <v-col
                    cols="12"
                    sm="2"
                    md="12"
                    class="pt-0"
                    style="margin-top: -22px"
                  >
                    <BtnFiltro :loading="load" @clear="limpiarFiltro()" />
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-card class="mt-2">
          <v-tabs v-model="tabActiva">
            <v-tab>Retenciones</v-tab>
            <v-tab>Percepciones</v-tab>

            <!-- RETENCIONES -->
            <v-tabs-items v-model="tabActiva">
              <v-tab-item>
                <v-data-table
                  class="cebra elevation-0 mt-2"
                  item-key="id"
                  sort-by="id"
                  :headers="headersRetenciones"
                  :items="retencionesFiltradas"
                  :loading="load"
                  sort-desc
                  dense
                  :search="searchRetenciones"
                >
                  <template v-slot:top>
                    <v-row
                      class="d-flex pa-2 h-mobile"
                      style="justify-content: space-between; height: 4.3rem"
                      no-gutters
                      hide-details
                    >
                      <v-col cols="12" sm="3" class="py-2">
                        <div
                          style="display: flex; align-items: center; gap: 10px"
                        >
                          <v-btn
                            class=""
                            :x-small="$vuetify.breakpoint.xs"
                            :small="!$vuetify.breakpoint.xs"
                            @click="abrirModalArchivos"
                          >
                            ARCHIVOS
                            <v-icon
                              color="green darken-3"
                              :small="$vuetify.breakpoint.xs"
                              right
                            >
                              fas fa-file-alt
                            </v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="3" class="py-2">
                        <div
                          style="display: flex; align-items: center; gap: 10px"
                        >
                          <SearchDataTable v-model="searchRetenciones" />
                          <v-btn
                            icon
                            color="primary"
                            dark
                            title="Reprocesar los filtrados."
                            @click="reprocesarMasivo"
                          >
                            <v-icon
                              color="
                       green
                      "
                            >
                              fas fa-sync-alt
                            </v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>

                    <v-divider></v-divider>
                  </template>

                  <template v-slot:[`item.acciones`]="{ item }">
                    <div>
                      <v-btn
                        @click="abrirModalCoincidencias(item)"
                        icon
                        title="Coincidencias"
                        :disabled="
                          item.Estado == 1 ||
                          item.Estado == 2 ||
                          item.Estado == 4
                        "
                      >
                        <v-icon small color="blue lighten-2">
                          fas fa-columns
                        </v-icon>
                      </v-btn>
                      <v-btn
                        @click="reprocesar(item)"
                        icon
                        title="Reprocesar"
                        :disabled="item.Estado == 1 || item.Estado == 4"
                      >
                        <v-icon small color="green lighten-2">
                          fas fa-sync
                        </v-icon>
                      </v-btn>
                      <BtnConfirmar
                        :icon_button="true"
                        :small="true"
                        :disabled="item.Estado != 1 || item.Estado == 4"
                        icono="fas fa-undo"
                        @action="desasignar(item)"
                        color="red lighten-2"
                        title="Desasignar"
                        texto="¿Desea desasignar esta retención?"
                      />
                      <v-tooltip
                        v-if="item.Estado == 4"
                        right
                        color="indigo darken-1"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon small color="indigo lighten-2">
                              fas fa-info-circle
                            </v-icon>
                          </v-btn>
                        </template>
                        <div>
                          <h5 class="">{{ item.Comentario }}</h5>
                        </div>
                      </v-tooltip>
                    </div>
                  </template>

                  <template
                    v-slot:[`item.Denominacion_razon_social`]="{ item }"
                  >
                    <span
                      style="
                        text-overflow: ellipsis ellipsis;
                        white-space: nowrap;
                      "
                      :style="
                        item.Denominacion_razon_social.length > 30
                          ? 'font-size: 10px;'
                          : 'font-size: 12px;'
                      "
                    >
                      {{ item.Denominacion_razon_social }}
                    </span>
                  </template>
                  <template v-slot:[`item.Monto`]="{ item }">
                    {{ formatMonto(item.Monto) }}
                  </template>
                </v-data-table>
              </v-tab-item>

              <!-- PERCEPCIONES -->
              <v-tab-item>
                <v-data-table
                  class="cebra elevation-0 mt-2"
                  item-key="id"
                  sort-by="id"
                  :headers="headersPercepciones"
                  :items="percepcionesFiltradas"
                  :loading="load"
                  sort-desc
                  dense
                  :search="searchPercepciones"
                >
                  <template v-slot:top>
                    <v-row
                      class="d-flex pa-2 h-mobile"
                      style="justify-content: space-between; height: 4.3rem"
                      no-gutters
                      hide-details
                    >
                      <v-col cols="12" sm="3" class="py-2">
                        <div
                          style="display: flex; align-items: center; gap: 10px"
                        >
                          <v-btn
                            class=""
                            :x-small="$vuetify.breakpoint.xs"
                            :small="!$vuetify.breakpoint.xs"
                            @click="abrirModalArchivos"
                          >
                            ARCHIVOS
                            <v-icon
                              color="green darken-3"
                              :small="$vuetify.breakpoint.xs"
                              right
                            >
                              fas fa-file-alt
                            </v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="3" class="py-2">
                        <div
                          style="display: flex; align-items: center; gap: 10px"
                        >
                          <SearchDataTable v-model="searchPercepciones" />
                          <v-btn
                            icon
                            color="primary"
                            dark
                            title="Reprocesar los filtrados."
                            @click="reprocesarMasivo"
                          >
                            <v-icon
                              color="
                       green
                      "
                            >
                              fas fa-sync-alt
                            </v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>

                    <v-divider></v-divider>
                  </template>

                  <template v-slot:[`item.acciones`]="{ item }">
                    <div>
                      <v-btn
                        @click="abrirModalCoincidencias(item)"
                        icon
                        title="Coincidencias"
                        :disabled="
                          item.Estado == 1 ||
                          item.Estado == 2 ||
                          item.Estado == 4
                        "
                      >
                        <v-icon small color="blue lighten-2">
                          fas fa-columns
                        </v-icon>
                      </v-btn>
                      <v-btn
                        @click="reprocesar(item)"
                        icon
                        title="Reprocesar"
                        :disabled="item.Estado == 1 || item.Estado == 4"
                      >
                        <v-icon small color="green lighten-2">
                          fas fa-sync
                        </v-icon>
                      </v-btn>
                      <BtnConfirmar
                        :icon_button="true"
                        :small="true"
                        :disabled="item.Estado != 1"
                        icono="fas fa-undo"
                        @action="desasignar(item)"
                        color="red lighten-2"
                        title="Desasignar"
                        texto="¿Desea desasignar esta retención?"
                      />
                      <v-tooltip
                        v-if="item.Estado == 4"
                        right
                        color="indigo darken-1"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon small color="indigo lighten-2">
                              fas fa-info-circle
                            </v-icon>
                          </v-btn>
                        </template>
                        <div>
                          <h5 class="">{{ item.Comentario }}</h5>
                        </div>
                      </v-tooltip>
                    </div>
                  </template>

                  <template
                    v-slot:[`item.Denominacion_razon_social`]="{ item }"
                  >
                    <span
                      style="
                        text-overflow: ellipsis ellipsis;
                        white-space: nowrap;
                      "
                      :style="
                        item.Denominacion_razon_social.length > 30
                          ? 'font-size: 10px;'
                          : 'font-size: 12px;'
                      "
                    >
                      {{ item.Denominacion_razon_social }}
                    </span>
                  </template>
                  <template v-slot:[`item.Monto`]="{ item }">
                    {{ formatMonto(item.Monto) }}
                  </template>
                </v-data-table>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
    <SubirRetencionesPercepciones
      v-model="modalSubirArchivo"
      @verItems="verItems($event)"
    />
    <Coincidencias
      v-model="modalCoincidencias"
      :coincidencias="coincidencias"
      :item-seleccionado="itemSeleccionado"
      :tipo="tabActiva"
      @asignado="setAsignado($event)"
    />
    <Encabezados
      v-model="modalEncabezados"
      :tipo="tabActiva"
      :encabezados="encabezados"
      @encabezadoEliminado="limpiarDatosEliminados($event)"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BtnFiltro from '../../components/util/BtnFiltro.vue'
import FechaPicker from '../../components/util/FechaPicker.vue'
import SubirRetencionesPercepciones from '../../components/administracion/SubirRetencionesPercepciones.vue'
import Coincidencias from '../../components/administracion/Coincidencias.vue'
import moment from 'moment'
import SearchDataTable from '../../components/util/SearchDataTable.vue'
import { format_money } from '../../util/utils'
import BtnConfirmar from '../../components/util/BtnConfirmar.vue'
import Encabezados from '../../components/administracion/Encabezados.vue'

export default {
  data() {
    return {
      panel: 0,
      load: false,
      tabActiva: 0,
      searchRetenciones: '',
      searchPercepciones: '',
      empresas: [],
      modalSubirArchivo: false,
      modalCoincidencias: false,
      modalReprocesar: false,
      modalEncabezados: false,
      retencionSeleccionada: {},

      itemsSeleccionados: [],
      itemSeleccionado: {},

      proveedores: [],
      cuits: [],
      cuitsFiltrados: [],

      filtro: {
        proveedor: null,
        cuit: null,
        empresa: {},
        fecha_desde: '',
        fecha_hasta: '',
        estado: null
      },

      retenciones: [],
      retencionesFiltradas: [],

      percepciones: [],
      percepcionesFiltradas: [],
      estados: [],
      encabezados: [],

      coincidencias: [],

      headersRetenciones: [
        {
          text: 'Proveedor',
          value: 'Denominacion_razon_social',
          width: '300px'
        },
        { text: 'Monto', value: 'Monto' },
        { text: 'CUIT/Referencia', value: 'Cuit_proveedor' },
        // { text: "Fecha de gravado", value: "Fecha_format" },
        { text: 'Estado', value: 'nombre_estado' },
        { text: 'Fecha registración', value: 'fechaRegistracionformat' },
        {
          text: 'Acciones',
          value: 'acciones',
          sortable: false,
          align: 'center'
        }
      ],

      headersPercepciones: [
        {
          text: 'Agente',
          value: 'Denominacion_razon_social',
          width: '300px'
        },
        { text: 'Monto', value: 'Monto' },
        { text: 'CUIT/CUIL', value: 'Cuit_agente' },
        // { text: "Fecha de gravado", value: "Fecha_format" },
        { text: 'Estado', value: 'nombre_estado' },
        { text: 'Fecha registración', value: 'fechaRegistracionformat' },
        {
          text: 'Acciones',
          value: 'acciones',
          sortable: false,
          align: 'center'
        }
      ]
    }
  },

  async created() {
    this.empresas = this.familias
    await this.getEstados()
  },

  methods: {
    async getRetenciones(filtros) {
      this.$store.state.loading = true
      await this.$store
        .dispatch('retencionesPercepciones/getRetenciones', filtros)
        .then(async res => {
          if (res.exito) {
            this.retenciones = res.data
            await this.getEncabezados()
            this.retenciones.forEach(retencion => {
              retencion.nombre_estado = this.estados.find(
                estado => estado.id === retencion.Estado
              )?.nombre_estado
              retencion.orden = this.estados.find(
                estado => estado.id === retencion.Estado
              )?.orden

              retencion.fechaRegistracionformat = moment(retencion.Fecha_registracion).format('DD/MM/YYYY') 
            })

            this.retenciones.sort((a,b) => a.orden - b.orden)
          } else {
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'error'
            })
          }
        })
      this.$store.state.loading = false
    },

    async getPercepciones(filtros) {
      this.$store.state.loading = true
      await this.$store
        .dispatch('retencionesPercepciones/getPercepciones', filtros)
        .then(async res => {
          if (res.exito) {
            this.percepciones = res.data
            await this.getEncabezados()
            this.percepciones.forEach(percepcion => {
              percepcion.nombre_estado = this.estados.find(
                estado => estado.id === percepcion.Estado
              )?.nombre_estado
              percepcion.orden = this.estados.find(
                estado => estado.id === percepcion.Estado
              )?.orden

              percepcion.fechaRegistracionformat = moment(percepcion.Fecha_registracion).format('DD/MM/YYYY')
            })

            this.percepciones.sort((a,b) => a.orden - b.orden)
          } else {
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'error'
            })
          }
        })
      this.$store.state.loading = false
    },

    async getEstados() {
      this.$store.state.loading = true
      await this.$store.dispatch('comprobantesAfip/getEstados').then(res => {
        if (res.exito) {
          this.estados = res.data
        } else {
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'error'
          })
        }
      })
      this.$store.state.loading = false
    },

    async getEncabezados() {
      let arrayIdsEncabezados = []
      let tipo = ''

      if (this.tabActiva == 0) {
        arrayIdsEncabezados = Array.from(
          new Set(this.retenciones.map(retencion => retencion.Retencion_id))
        )
        tipo = 'RETENCION'

        if (this.retenciones.length > 0) {
          this.$store.state.loading = true
          await this.$store
            .dispatch('retencionesPercepciones/getEncabezados', {
              idsEncabezados: arrayIdsEncabezados,
              tipo: tipo
            })
            .then(res => {
              if (res.exito) {
                this.encabezados = res.data
                this.encabezados.forEach(encabezado => {
                  encabezado.nombre_empresa = this.familias.find(
                    empresa => empresa.id === encabezado.Empresa_id
                  )?.nombre
                })
              } else {
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'error'
                })
              }
            })
          this.$store.state.loading = false
        }
      } else {
        arrayIdsEncabezados = Array.from(
          new Set(this.percepciones.map(retencion => retencion.Percepcion_id))
        )
        tipo = 'PERCEPCION'

        if (this.percepciones.length > 0) {
          this.$store.state.loading = true
          await this.$store
            .dispatch('retencionesPercepciones/getEncabezados', {
              idsEncabezados: arrayIdsEncabezados,
              tipo: tipo
            })
            .then(res => {
              if (res.exito) {
                this.encabezados = res.data
                this.encabezados.forEach(encabezado => {
                  encabezado.nombre_empresa = this.familias.find(
                    empresa => empresa.id === encabezado.Empresa_id
                  )?.nombre
                })
              } else {
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'error'
                })
              }
            })
          this.$store.state.loading = false
        }
      }
    },

    formatFecha(fecha) {
      return moment(fecha).utc(fecha).format('DD/MM/YYYY')
    },
    formatMonto(monto) {
      return format_money(monto)
    },

    abrirModal() {
      this.modalSubirArchivo = true
    },

    async verItems(val) {
      if (val.tipo == 'RETENCION') {
        this.tabActiva = 0
      }
      if (val.tipo == 'PERCEPCION') {
        this.tabActiva = 1
      }

      this.filtro.empresa = val.empresa
      this.filtro.fecha_desde = val.minFecha

      await this.buscar()
    },

    async abrirModalCoincidencias(item) {
      this.itemSeleccionado = item
      const arrayIdCoincidencias = item.Posibles_coincidencias.split(',')

      this.$store.state.loading = true
      await this.$store
        .dispatch(
          'retencionesPercepciones/getCoincidencias',
          arrayIdCoincidencias
        )
        .then(res => {
          if (res.exito) {
            this.coincidencias = res.data
          } else {
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'error'
            })
          }
        })

      this.$store.state.loading = false
      this.modalCoincidencias = true
    },

    abrirModalReprocesar() {
      if (this.tabActiva == 0) {
        this.itemsSeleccionados = this.retencionesFiltradas
      } else {
        this.itemsSeleccionados = this.percepcionesFiltradas
      }
      this.modalReprocesar = true
    },

    async abrirModalArchivos() {
      await this.getEncabezados()

      this.modalEncabezados = true
    },

    async reprocesar(item) {
      const fechaRegistracion = moment(item.Fecha_registracion)
      const fechaRangoSeisMeses = fechaRegistracion
        .subtract(6, 'months')
        .format('DD/MM/YYYY')

      item.fecha_desde = fechaRangoSeisMeses

      if (this.tabActiva == 0) {
        item.tipo = 'RETENCION'
      } else {
        item.tipo = 'PERCEPCION'
      }

      this.$store.state.loading = true
      await this.$store
        .dispatch('retencionesPercepciones/reprocesar', item)
        .then(async res => {
          if (res.exito) {
            await this.getPercepciones({
              fecha_desde: item.Fecha_registracion,
              empresa_id: this.filtro.empresa.id
            })
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'success'
            })
          } else {
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'error'
            })
          }
        })
      this.$store.state.loading = false
    },

    async reprocesarMasivo() {
      let itemsReprocesar = []

      if (this.tabActiva == 0) {
        const retencionesSinDetMovId = this.retencionesFiltradas.filter(
          retencion => retencion.Estado != 1
        )
        retencionesSinDetMovId.forEach(retencion => {
          const fechaRegistracion = moment(retencion.Fecha_registracion)
          const fechaRangoSeisMeses = fechaRegistracion
            .subtract(6, 'months')
            .format('DD/MM/YYYY')

          retencion.fecha_desde = fechaRangoSeisMeses
          retencion.tipo = 'RETENCION'
        })

        itemsReprocesar = retencionesSinDetMovId
      } else {
        const percepcionesSinDetMovId = this.percepcionesFiltradas.filter(
          retencion => retencion.Estado != 1
        )
        percepcionesSinDetMovId.forEach(percepcion => {
          const fechaRegistracion = moment(percepcion.Fecha_registracion)
          const fechaRangoSeisMeses = fechaRegistracion
            .subtract(6, 'months')
            .format('DD/MM/YYYY')

          percepcion.fecha_desde = fechaRangoSeisMeses
          percepcion.tipo = 'PERCEPCION'
        })

        itemsReprocesar = percepcionesSinDetMovId
      }

      this.$store.state.loading = true
      await this.$store
        .dispatch('retencionesPercepciones/reprocesarMasivo', itemsReprocesar)
        .then(async res => {
          if (res.exito) {
            await this.getRetenciones({
              fecha_desde: this.filtro.fecha_desde,
              empresa_id: this.filtro.empresa.id
            })
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'success'
            })
          } else {
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'error'
            })
          }
        })
      this.$store.state.loading = false
    },

    async desasignar(item) {
      if (this.tabActiva == 0) {
        item.tipo = 'RETENCION'
      } else {
        item.tipo = 'PERCEPCION'
      }
      this.$store.state.loading = true
      await this.$store
        .dispatch('retencionesPercepciones/desasignar', item)
        .then(async res => {
          if (res.exito) {
            this.retencionesFiltradas.forEach(retencion => {
              if (retencion.Id == item.Id) {
                retencion.nombre_estado = 'PENDIENTE'
                retencion.Estado = 2
              }
            })
            await this.reprocesar(item)
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'success'
            })
          } else {
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'error'
            })
          }
        })
      this.$store.state.loading = false
    },

    async setAsignado(val) {
      if (val) {
        await this.getRetenciones({
          fecha_desde: this.filtro.fecha_desde,
          empresa_id: this.filtro.empresa.id
        })
        this.retencionesFiltradas = this.retenciones
        await this.getPercepciones({
          fecha_desde: this.filtro.fecha_desde,
          empresa_id: this.filtro.empresa.id
        })
        this.percepcionesFiltradas = this.percepciones
      }
    },

    limpiarDatosEliminados(event) {
      if (event) {
        this.limpiarFiltro()
        this.retencionesFiltradas = []
        this.percepcionesFiltradas = []
      }
    },

    limpiarFiltro() {
      this.filtro.empresa = {}
      this.filtro.proveedor = null
      this.filtro.cuit = null
      this.filtro.fecha_desde = ''
      this.filtro.fecha_hasta = ''
      this.filtro.estado = null
      this.filtro.fecha_desde.toString()
      this.filtro.fecha_hasta.toString()
      this.cuitsFiltrados = []
    },
    async buscar() {
      if (
        (this.filtro.fecha_desde.length == 0 ||
          this.filtro.fecha_desde == null) &&
        (Object.keys(this.filtro.empresa).length > 0 || this.filtro.empresa)
      ) {
        this.$store.dispatch('show_snackbar', {
          text: 'Debes seleccionar empresa y fecha desde.',
          color: 'warning'
        })
        return
      }

      let datos = []
      let datosFiltrados = []

      if (this.tabActiva == 0) {
        await this.getRetenciones({
          fecha_desde: this.filtro.fecha_desde,
          empresa_id: this.filtro.empresa.id
        })
        datos = this.retenciones
      } else {
        await this.getPercepciones({
          fecha_desde: this.filtro.fecha_desde,
          empresa_id: this.filtro.empresa.id
        })
        datos = this.percepciones
      }
      const retenciones = this.retenciones

      datosFiltrados = datos.filter(dato => {
        const matchFecha = () => {
          if (this.filtro.fecha_hasta && this.filtro.fecha_hasta.length > 0) {
            const filtro_fecha_hasta = moment(
              this.filtro.fecha_hasta,
              'DD/MM/YYYY',
              true
            ).startOf('day')
            const retencion_fecha = moment(dato.Fecha_registracion).startOf(
              'day'
            )

            return retencion_fecha.isSameOrBefore(filtro_fecha_hasta)
          } else {
            return true
          }
        }

        const matchProveedor = () => {
          if (this.filtro.proveedor) {
            return this.filtro.proveedor === dato.Denominacion_razon_social
          } else {
            return true
          }
        }

        const matchCuit = () => {
          if (this.filtro.cuit) {
            let cuit = null
            this.tabActiva == 0
              ? (cuit = dato.Cuit_proveedor)
              : (cuit = dato.Cuit_agente)
            return this.filtro.cuit === cuit
          } else {
            return true
          }
        }

        const matchEstado = () => {
          if (
            this.filtro.estado &&
            Object.keys(this.filtro.estado).length > 0
          ) {
            return this.filtro.estado.id === dato.Estado
          } else {
            return true
          }
        }

        return matchFecha() && matchProveedor() && matchCuit() && matchEstado()
      })

      this.tabActiva == 0
        ? (this.retencionesFiltradas = datosFiltrados)
        : (this.percepcionesFiltradas = datosFiltrados)
    },

    setProveedores() {
      if (this.tabActiva == 0) {
        this.proveedores = Array.from(
          new Set(
            this.retenciones.map(
              retencion => retencion.Denominacion_razon_social
            )
          )
        )
      } else {
        this.proveedores = Array.from(
          new Set(
            this.percepciones.map(
              retencion => retencion.Denominacion_razon_social
            )
          )
        )
      }
    },

    setCuits() {
      if (this.tabActiva == 0) {
        this.cuits = Array.from(
          new Set(this.retenciones.map(retencion => retencion.Cuit_proveedor))
        )
      } else {
        this.cuits = Array.from(
          new Set(this.percepciones.map(retencion => retencion.Cuit_agente))
        )
      }
      this.cuitsFiltrados = this.cuits
    },

    setCuit() {
      this.cuitsFiltrados = this.cuits.filter(cuit => {
        const cuitEncontrado = this.retenciones.find(retencion => {
          return retencion.Denominacion_razon_social === this.filtro.proveedor
        })?.Cuit_proveedor

        return cuit == cuitEncontrado
      })
    },

    // getCoincidencias(value) {
    //   this.coincidencias = value
    // }
  },

  watch: {
    async 'filtro.fecha_desde'(val) {
      if (
        val &&
        val.length > 0 &&
        Object.keys(this.filtro.empresa).length > 0
      ) {
        if (this.tabActiva == 0) {
          await this.getRetenciones({
            fecha_desde: val
          })
          this.setProveedores()
          this.setCuits()
        } else {
          await this.getPercepciones({
            fecha_desde: val
          })
          this.setProveedores()
          this.setCuits()
        }
      } else {
        this.filtro.proveedor = null
        this.filtro.cuit = null
        this.proveedores = []
        this.cuits = []
      }
    },

    tabActiva(val) {
      if (val) {
        this.limpiarFiltro()
        this.encabezados = []
      }
    }
  },

  computed: {
    ...mapState(['familias'])
  },

  components: {
    BtnFiltro,
    FechaPicker,
    SubirRetencionesPercepciones,
    Coincidencias,
    SearchDataTable,
    BtnConfirmar,
    Encabezados
  }
}
</script>
