<template>
  <v-dialog v-model="dialog" max-width="768" scrollable>
    <v-card>
      <v-card-title primary-title>
        <h4>Nuevo Talonario</h4>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-row :no-gutters="$vuetify.breakpoint.xs" class="mt-2">
          <v-col cols="12" sm="3" md="3" class="py-1">
            Empresa
            <v-autocomplete
              v-model="data.familia"
              item-text="nombre"
              item-value="id"
              hide-details
              outlined
              dense
              clearable
              :items="familias"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" md="3" class="py-1">
            Sucursal
            <v-autocomplete
              v-model="data.sucursal"
              item-text="provincia"
              item-value="id"
              hide-details
              outlined
              dense
              clearable
              :items="sucursales.filter(suc => suc.familia_id === data.familia)"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" md="3" class="py-1">
            Tipo
            <v-autocomplete
              v-model="data.tipo"
              item-text="Nombre"
              item-value="Id"
              hide-details
              outlined
              dense
              :items="tipos_talonarios"
              @change="setDesdeHasta"
            ></v-autocomplete>
          </v-col>

          <v-col cols="6" sm="3" xl="2" class="py-1">
            Nº Desde
            <v-text-field
              v-model.trim="data.nroDesde"
              hide-details
              outlined
              dense
              type="number"
              @change="validarRango"
            ></v-text-field>
          </v-col>
          <v-col cols="6" sm="3" xl="2" class="py-1">
            Nº Hasta
            <v-text-field
              v-model.trim="data.nroHasta"
              hide-details
              outlined
              dense
              type="number"
              @change="validarRango"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" md="3" class="py-2">
            Fecha desde
            <FechaPicker v-model="data.validoDesde" hide-details clearable />
          </v-col>
          <v-col cols="12" sm="3" md="3" class="py-2">
            Fecha hasta
            <FechaPicker v-model="data.validoHasta" hide-details clearable />
          </v-col>
        </v-row>

        <div class="d-flex mt-10">
          <v-btn
            @click="dialog = false"
            style="margin-left: auto"
            color="primary"
            >Volver</v-btn
          >
          <div class="ml-2">
            <BtnConfirmar
              :disabled="!rangoValido"
              @action="guardarNuevoTalonario()"
            />
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import BtnConfirmar from '../util/BtnConfirmar.vue'
import FechaPicker from '../util/FechaPicker.vue'

export default {
  data() {
    return {
      load: false,
      rangoValido: false,
      data: {
        familia: null,
        sucursal: null,
        tipo: null,
        validoDesde: '',
        validoHasta: '',
        nroDesde: null,
        nroHasta: null
      }
    }
  },

  methods: {
    async guardarNuevoTalonario() {
      if (
        this.data.familia &&
        this.data.sucursal &&
        this.data.tipo &&
        this.data.nroDesde &&
        this.data.nroHasta
      ) {
        this.$store.state.loading = true
        await this.$store
          .dispatch('talonarios/nuevoTalonario', {
            tipo: this.data.tipo,
            familia: this.data.familia,
            sucursal: this.data.sucursal,
            nroDesde: this.data.nroDesde,
            nroHasta: this.data.nroHasta
          })
          .then(res => {
            if (res.exito) {
              this.$swal.fire({
                icon: 'success',
                title: res.message
              })
              this.$emit('buscar', true)
              this.dialog = false
            } else {
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'error'
              })
            }
          })
        this.$store.state.loading = false
      } else {
        this.$store.dispatch('show_snackbar', {
          text: 'Debes completar los campos Empresa, Sucursal, Tipo, Nº Desde, Nº Hasta',
          color: 'warning'
        })

        this.data = {
          familia: null,
          sucursal: null,
          tipo: null,
          validoDesde: '',
          validoHasta: '',
          nroDesde: null,
          nroHasta: null
        }
      }
    },

    setDesdeHasta() {
      this.data.nroDesde = null
      this.data.nroHasta = null
    },

    async validarRango() {
      if (this.data.nroDesde && this.data.nroHasta) {
        await this.$store
          .dispatch('talonarios/validarRango', {
            tipo: this.data.tipo,
            nroDesde: this.data.nroDesde,
            nroHasta: this.data.nroHasta
          })
          .then(res => {
            if (!res.exito) {
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'warning'
              })
              this.rangoValido = res.exito
            } else {
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'success'
              })
              this.rangoValido = res.exito
            }
          })
      }
    }
  },

  watch: {
    dialog(val) {
      if (val) {
        this.data = {
          familia: null,
          sucursal: null,
          tipo: null,
          validoDesde: '',
          validoHasta: '',
          nroDesde: null,
          nroHasta: null
        }
      }
    },

    async 'data.nroHasta'(val) {
      if (this.data.nroHasta) {
        if (this.data.nroHasta < this.data.nroDesde) {
          this.$store.dispatch('show_snackbar', {
            text: 'El número desde debe ser menor que el número hasta.',
            color: 'warning'
          })
        }
      }
    }
  },

  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    ...mapState(['familias']),
    ...mapState('genericos', ['sucursales']),
    ...mapGetters(['una_familia'])
  },

  props: {
    value: Boolean,
    tipos_talonarios: Array
  },

  created() {
    if (this.una_familia) this.data.familia = this.familias[0].id
  },

  components: {
    BtnConfirmar,
    FechaPicker
  }
}
</script>
