<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <v-btn
              style="position: absolute; top: -15px; right: 55px; z-index: 1"
              color="success"
              small
              fab
              title="Nuevo Talonario"
              @click="abrirModalNuevoTalonario"
            >
              <v-icon>fas fa-plus</v-icon>
            </v-btn>
            <v-expansion-panel-header
              class="py-0 px-4 text-h5"
              style="cursor: default"
            >
              <div>
                <v-icon class="mb-1" left>fas fa-filter</v-icon>
                Filtros
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>

            <!-- Filtros -->
            <v-expansion-panel-content class="pt-1">
              <v-form @submit.prevent="buscar">
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Empresa
                    <v-autocomplete
                      v-model="filtro.familia"
                      item-text="nombre"
                      item-value="id"
                      hide-details
                      outlined
                      dense
                      clearable
                      :items="familias"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Sucursal
                    <v-autocomplete
                      v-model="filtro.sucursal"
                      item-text="provincia"
                      item-value="id"
                      hide-details
                      outlined
                      dense
                      clearable
                      :items="
                        sucursales.filter(
                          suc => suc.familia_id === filtro.familia
                        )
                      "
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="2" class="py-1">
                    Tipo
                    <v-autocomplete
                      v-model="filtro.tipo"
                      item-text="Nombre"
                      item-value="Id"
                      hide-details
                      outlined
                      dense
                      :items="tipos_talonarios"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6" sm="3" xl="2" class="py-1">
                    Nº Desde
                    <v-text-field
                      v-model.trim="filtro.nroDesde"
                      hide-details
                      outlined
                      dense
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="3" xl="2" class="py-1">
                    Nº Hasta
                    <v-text-field
                      v-model.trim="filtro.nroHasta"
                      hide-details
                      outlined
                      dense
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="2" class="py-1">
                    Fecha desde
                    <FechaPicker
                      v-model="filtro.validoDesde"
                      hide-details
                      clearable
                    />
                  </v-col>
                  <v-col cols="12" sm="3" md="2" class="py-1">
                    Fecha hasta
                    <FechaPicker
                      v-model="filtro.validoHasta"
                      hide-details
                      clearable
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="2"
                    md="12"
                    class="pt-0"
                    style="margin-top: -22px"
                  >
                    <BtnFiltro :loading="load" @clear="limpiarFiltro()" />
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-card class="mt-2">
          <v-data-table
            class="cebra elevation-0 mt-2"
            item-key="id"
            sort-by="id"
            :headers="headers"
            :items="talonarios"
            :loading="load"
            sort-desc
            dense
          >
            <template v-slot:[`item.acciones`]="{ item }">
              <v-btn
                class="mr-2"
                small
                icon
                color="success"
                @click="abrirModalEditar(item)"
                title="Editar"
              >
                <v-icon class="" small>fas fa-pen</v-icon>
              </v-btn>

              <!-- ALTERNAR CON EL HABILITAR Y DESHABILITAR -->
              <BtnConfirmar
                :icon_button="true"
                :color="item.Inhabilitado == 1 ? 'success' : 'error'"
                :small="true"
                :icono="item.Inhabilitado == 1 ? 'fas fa-check' : 'fas fa-ban'"
                @action="
                  item.Inhabilitado == 1 ? habilitar(item) : inhabilitar(item)
                "
                :title="item.Inhabilitado == 1 ? 'Habilitar' : 'Inhabilitar'"
                :texto="
                  item.Inhabilitado == 1
                    ? `¿Desea habilitar este talonario de tipo ${item.tipo_nombre} con número desde igual a ${item.Nro_desde} y número hasta igual a ${item.Nro_hasta}?`
                    : `¿Desea inhabilitar este talonario de tipo ${item.tipo_nombre} con número desde igual a ${item.Nro_desde} y número hasta igual a ${item.Nro_hasta}?`
                "
              />
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <NuevoTalonario
      :tipos_talonarios="tipos_talonarios"
      v-model="modalNuevoTalonario"
      @buscar="handleActualizar($event)"
    />
    <EditarTalonario
      :tipos_talonarios="tipos_talonarios"
      v-model="modalEditar"
      :talonario="talonarioSeleccionado"
      @buscar="handleActualizar($event)"
    />
  </div>
</template>

<script>
import FechaPicker from '../../components/util/FechaPicker.vue'
import BtnFiltro from '../../components/util/BtnFiltro.vue'
import BtnConfirmar from '../../components/util/BtnConfirmar.vue'

import { mapGetters, mapState } from 'vuex'
import NuevoTalonario from '../../components/administracion/NuevoTalonario.vue'
import talonarios from '../../modules/administracion/talonarios'
import EditarTalonario from '../../components/administracion/EditarTalonario.vue'

export default {
  data() {
    return {
      panel: 0,

      load: false,

      modalNuevoTalonario: false,
      modalEditar: false,

      sucursalesFiltradas: [],

      talonarioSeleccionado: null,

      tipos_talonarios: [],
      talonarios: [],
      filtro: {
        familia: null,
        sucursal: null,
        nroDesde: null,
        nroHasta: null,
        sucursal: null,
        validoDesde: '',
        validoHasta: '',
        tipo: null
      },

      headers: [
        { text: 'Tipo', value: 'tipo_nombre' },
        { text: 'Sucursal', value: 'sucursalNombre' },
        { text: 'Nº Desde', value: 'Nro_desde' },
        { text: 'Nº Hasta', value: 'Nro_hasta' },
        { text: 'Usuario creacion', value: 'Username' },

        {
          text: 'Acciones',
          value: 'acciones',
          sortable: false
        }
      ]
    }
  },
  methods: {
    async buscar() {
      // GET talonarios
      if (this.filtro.familia) {
        await this.$store
          .dispatch('talonarios/getTalonarios', {
            ...this.filtro
          })
          .then(res => {
            if (res.exito) {
              this.talonarios = res.data
              this.talonarios.forEach(talonario => {
                talonario.sucursalNombre = this.sucursales.find(
                  suc => suc.id === talonario.Sucursal_id
                )?.provincia
              })
            }
          })
      } else {
        this.$store.dispatch('show_snackbar', {
          text: 'Debes seleccionar Empresa y Sucursal.',
          color: 'warning'
        })
      }
    },

    async inhabilitar(item) {
      this.$store.state.loading = true
      await this.$store
        .dispatch('talonarios/inhabilitarTalonario', {
          talonario_id: item.Id
        })
        .then(async res => {
          if (res.exito) {
            // actualzar la busqueda
            await this.buscar()
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'success'
            })
          } else {
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'error'
            })
          }
        })
      this.$store.state.loading = false
    },

    async habilitar(item) {
      this.$store.state.loading = true
      await this.$store
        .dispatch('talonarios/habilitarTalonario', {
          talonario_id: item.Id
        })
        .then(async res => {
          if (res.exito) {
            // actualzar la busqueda
            await this.buscar()
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'success'
            })
          } else {
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'error'
            })
          }
        })
      this.$store.state.loading = false
    },

    handleActualizar(event) {
      if (event) {
        this.buscar()
      }
    },

    abrirModalNuevoTalonario() {
      this.modalNuevoTalonario = true
    },

    abrirModalEditar(item) {
      this.talonarioSeleccionado = item
      this.modalEditar = true
    },

    limpiarFiltro() {
      this.filtro = {
        familia: null,
        sucursal: null,
        nroDesde: null,
        nroHasta: null,
        sucursal: null,
        validoDesde: '',
        validoHasta: '',
        tipo: null
      }
    }
  },
  computed: {
    ...mapState('genericos', ['sucursales']),
    ...mapState(['familias']),
    ...mapGetters(['una_familia'])
  },

  async created() {
    if (this.una_familia) this.data.familia = this.familias[0].id

    await this.$store.dispatch('talonarios/getTipos').then(res => {
      if (res.exito) {
        this.tipos_talonarios = res.data
      }
    })

    await this.$store.dispatch('genericos/get_sucursales')
  },

  components: {
    FechaPicker,
    BtnFiltro,
    NuevoTalonario,
    BtnConfirmar,
    EditarTalonario
  }
}
</script>
